import { useAxios } from '@/app/composable';
import { Model } from '@/app/interfaces';
import store from '@/app/store';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { NameIDType } from '@/modules/data-checkin/asset-config.types';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ModelAPI } from '../api';
import {
    accessLevelOptions,
    audioOptions,
    calculationSchemeOptions,
    currencyOptions,
    expectedUsageOptions,
    imageOptions,
    licenseOptions,
    licenses,
    modelOptions,
    offlineRetentionOptions,
    otherFilesRetentionOptions,
    reimbursementMethodOptions,
    spatialCoverageOptions,
    spatialResolutionOptions,
    temporalCoverageOptions,
    temporalResolutionOptions,
    textBinaryOptions,
    textOptions,
    videoOptions,
} from '../config/asset';
import { AccrualPeriodicityInterval, AccrualPeriodicityUnits, AssetType } from '../constants';
import { AssetSchema } from '../types';
import { useAsset } from './asset';

export function useAssetMetadata() {
    const { assetTypeName } = useAsset();
    const { exec } = useAxios(true);

    const asset = ref<any>(null);
    const accessLevel = ref<any>(null);
    const copyrightOwner = ref<any>(null);
    const tagOptions = ref<any>([]);
    const spatialIDfields = ref<any>([]);

    const locationOptions = computed(() => store.getters.locations.locationOptions);
    const domains = computed(() => store.getters.dataModel.domains);

    const assetType = computed(() => {
        if (asset.value && asset.value.assetTypeId) return assetTypeName(asset.value.assetTypeId);
        return AssetType.Dataset;
    });

    const checkLicense = (license: any) => {
        if (license)
            if (license.label !== 'Custom') {
                const licenseMetadata = licenses.find((element) => element.license === license.label);
                if (licenseMetadata) {
                    asset.value.metadata.license.license = licenseMetadata.license;
                    asset.value.metadata.license.link = licenseMetadata.link;
                    asset.value.metadata.license.expectedUsage = licenseMetadata.expectedUsage;
                    asset.value.metadata.license.offlineRetention =
                        asset.value.structure?.type === 'other'
                            ? otherFilesRetentionOptions
                            : licenseMetadata.offlineRetention;
                }
            } else {
                asset.value.metadata.license.license = 'Custom';
                asset.value.metadata.license.link = null;
                asset.value.metadata.license.expectedUsage = Object.keys(expectedUsageOptions);
                asset.value.metadata.license.offlineRetention =
                    asset.value.structure?.type === 'other' ? otherFilesRetentionOptions : offlineRetentionOptions;
                if (asset.value.metadata.pricing) {
                    asset.value.metadata.pricing.reimbursementMethod = [];
                    asset.value.metadata.pricing.cost = null;
                    asset.value.metadata.pricing.currency = 'EUR';
                    asset.value.metadata.pricing.calculationScheme = null;
                }
            }
    };

    const domainsOptions = computed(() =>
        domains.value.reduce((acc: any, domain: any) => {
            return R.assoc(domain.id, domain.name, acc);
        }, {}),
    );

    const domainsIdToUID = computed(() =>
        domains.value.reduce((acc: any, domain: any) => {
            return R.assoc(domain.id, R.pick(['uid', 'majorVersion'], domain), acc);
        }, {}),
    );

    const formatOptions = computed(() => {
        if (asset.value?.metadata?.distribution)
            switch (asset.value.metadata.distribution.type) {
                case 'Text':
                    return textOptions;
                case 'Text and Binary':
                    return textBinaryOptions;
                case 'Image':
                    return imageOptions;
                case 'Model':
                    return modelOptions;
                case 'Video':
                    return videoOptions;
                case 'Audio':
                    return audioOptions;
                case null:
                default:
                    return [];
            }
        return [];
    });

    const customLicense = computed(
        () =>
            asset.value?.metadata?.license &&
            (asset.value.metadata.license.license === undefined ||
                asset.value.metadata.license.license === null ||
                asset.value.metadata.license.license === 'Custom'),
    );

    const spatialIDfieldName = computed(() => {
        if (
            asset.value?.metadata?.extent?.spatialCoverage.unit &&
            asset.value.metadata.extent.spatialCoverage.unit.includes('Specific') &&
            asset.value.metadata.extent.spatialCoverage.unit !== 'Specific Continent/Countries'
        ) {
            const camelCaseField = asset.value.metadata.extent.spatialCoverage.unit.substring(9).split(' ').join('');
            const lowercaseFieldName = `${camelCaseField.charAt(0).toLowerCase()}${camelCaseField.substring(1)}`;
            const uppercaseFieldName = `${camelCaseField.charAt(0).toUpperCase()}${camelCaseField.substring(1)}`;
            const spatialField = spatialIDfields.value.find((field: any) =>
                [lowercaseFieldName, uppercaseFieldName].includes(field.name),
            );
            if (spatialField) return R.pick(['uid', 'name'], spatialField);
        }
        return null;
    });

    const temporalFields: Ref<{ id: number; label: string; value: string }[]> = computed(() => {
        if (asset.value?.structure?.temporalFields)
            return asset.value.structure.temporalFields.reduce(
                (acc: Record<string, unknown>[], field: NameIDType, idx: number) => {
                    acc.push({
                        id: idx,
                        label: field.name.replaceAll('__', ' > '),
                        value: { uid: field.uid, name: field.name },
                    });
                    return acc;
                },
                [],
            );
        return [];
    });

    const spatialFields: Ref<{ id: number; label: string; value: string }[]> = computed(() => {
        if (asset.value?.structure?.spatialFields)
            return asset.value.structure.spatialFields.reduce(
                (acc: Record<string, unknown>[], field: NameIDType, idx: number) => {
                    acc.push({
                        id: idx,
                        label: field.name.replaceAll('__', ' > '),
                        value: { uid: field.uid, name: field.name },
                    });
                    return acc;
                },
                [],
            );
        return [];
    });

    const getFieldDescription = (concept: AssetSchema, domainConcepts: any[]) => {
        if (concept.customConceptDescription) return concept.customConceptDescription;

        // find concept based on its uid in asset's schema
        const domainConcept = domainConcepts.find((conc: any) => conc.uid === concept.uid);
        if (!domainConcept) return '';

        const cleanedName = concept.field.split(':')[0]; // in case of anonymization

        // if concept name is not the same then search for related concept
        if (cleanedName !== domainConcept.name && concept.parentUids && concept.parentUids.length > 0) {
            const parentUid = concept.parentUids[concept.parentUids.length - 1];
            const parentId = domainConcepts.find((conc: any) => conc.uid === parentUid)?.id;
            const relatedConcept = domainConcepts.find(
                (conc: any) => conc.referenceConceptId === domainConcept.id && conc.parentId === parentId,
            );
            // if found then search for the correct prefix in reference prefix
            if (relatedConcept) {
                const referenceConcept = domainConcept.name.charAt(0).toUpperCase() + domainConcept.name.slice(1);
                const relatedConceptName = relatedConcept.name.charAt(0).toUpperCase() + relatedConcept.name.slice(1);

                for (let i = 0; i < relatedConcept.referencePrefix.length; i++)
                    if (
                        [
                            `${relatedConcept.referencePrefix[i].prefix}${referenceConcept}`,
                            `${relatedConcept.referencePrefix[i].prefix}${relatedConceptName}`,
                        ].includes(cleanedName)
                    )
                        return relatedConcept.referencePrefix[i].description;

                return relatedConcept.description;
            }
        }

        return domainConcept.description;
    };

    const createTreeStructure = (schema: AssetSchema[], primaryConcept: any, domainConcepts: any[]): any => {
        const dataTypes = ['integer', 'double', 'string', 'boolean', 'datetime', 'time', 'date', 'base64binary'];
        const extractChildren = (parent: string): Record<string, unknown>[] =>
            schema.reduce((result: Record<string, unknown>[], field: AssetSchema) => {
                if (field.path && field.path.length > 0 && field.path.join('.') === parent && field.type) {
                    result.push({
                        id: domainConcepts.find((concept: any) => concept.uid === field.uid)?.id,
                        uid: field.uid,
                        key: field.field,
                        name: [...field.path, field.field].join('.'),
                        path: field.path,
                        type: !dataTypes.includes(field.type) ? 'object' : field.type,
                        description: getFieldDescription(field, domainConcepts),
                        annotation: field.annotation,
                        children: !dataTypes.includes(field.type) ? extractChildren(`${parent}.${field.field}`) : [],
                    });
                }
                return result;
            }, [] as Record<string, unknown>[]);
        return [
            {
                id: domainConcepts.find((concept: any) => concept.uid === primaryConcept.uid)?.id,
                uid: primaryConcept.uid,
                key: primaryConcept.name,
                name: primaryConcept.name,
                path: [],
                type: 'object',
                description: domainConcepts.find((conc: any) => conc.uid === primaryConcept.uid)?.description,
                children: extractChildren(primaryConcept.name),
            },
        ];
    };

    const getGeneralSchema = computed(() => {
        return [
            {
                type: 'multiselect',
                name: 'tags',
                label: 'Tags',
                placeholder: 'Add tags',
                options: tagOptions.value,
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                preserveSearch: true,
                preselectFirst: false,
                taggable: true,
                help:
                    'A list of keywords and/or arbitrary textual tags associated with the asset by its data provider.',
                helpPosition: 'before',
                validation: 'bail|required|max:10|minTag:2|maxTag:50|alphanumericTag',
                validationRules: {
                    minTag: ({ value }: any) => value.every((tag: any) => tag.length >= 2),
                    maxTag: ({ value }: any) => value.every((tag: any) => tag.length <= 50),
                    alphanumericTag: ({ value }: any) =>
                        value.every((tag: any) => new RegExp(/^[a-zA-Z0-9 ]+$/).test(tag)),
                },
                validationMessages: {
                    minTag: 'Tags must be at least 2 characters long.',
                    maxTag: 'Tags must be less than or equal to 50 characters long.',
                    alphanumericTag: 'Tags can only contain letters, numbers and spaces.',
                },
                labelClass: ['pb-1'],
                errorBehavior: 'value',
            },
        ];
    });

    const getDistributionSchema = computed(() => {
        return [
            // Displayed only in the case of 'Other files'
            {
                type: 'multiselect',
                name: 'format',
                label: 'Format',
                placeholder: 'Select format',
                options: formatOptions.value,
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                preserveSearch: true,
                preselectFirst: false,
                taggable: true,
                help:
                    'The format to which the data will be available through the platform. It is not necessarily the same format with which the data that are uploaded comply.',
                helpPosition: 'before',
                validation: 'bail|required|max:10|minTag:2|maxTag:20|alphanumericTag',
                validationRules: {
                    minTag: ({ value }: any) => value.every((tag: any) => tag.length >= 2),
                    maxTag: ({ value }: any) => value.every((tag: any) => tag.length <= 20),
                    alphanumericTag: ({ value }: any) =>
                        value.every((tag: any) => new RegExp(/^[a-zA-Z0-9]+$/).test(tag)),
                },
                validationMessages: {
                    minTag: 'Format must be at least 2 characters long.',
                    maxTag: 'Format must be less than or equal to 20 characters long.',
                    alphanumericTag: 'Format can only contain letters and numbers.',
                },
                disabled: !asset.value.metadata.distribution || !asset.value.metadata.distribution.type,
                labelClass: ['pb-1'],
                outerClass: ['mb-0'],
                errorBehavior: 'value',
            },
        ];
    });

    const getTemporalResolutionSchema = computed(() => {
        if (asset.value?.metadata?.extent) {
            const schema: any = [
                {
                    type: 'select',
                    name: 'unit',
                    key: 'temporalResolutionUnit',
                    label: 'Temporal Resolution Unit',
                    placeholder: 'Select temporal resolution unit',
                    options: temporalResolutionOptions,
                    help:
                        'The frequency of acquiring new data from the same data source (e.g. as part of a dynamic process from a system/location/sensor).',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'temporal Resolution Unit',
                    inputClass: 'form-select w-full',
                    labelClass: ['pb-1'],
                    errorBehavior: 'value',
                },
            ];
            if (
                asset.value.metadata.extent.temporalResolution?.unit &&
                asset.value.metadata.extent.temporalResolution.unit !== 'Not applicable'
            )
                Array.prototype.push.apply(schema, [
                    {
                        type: 'number',
                        name: 'value',
                        key: 'temporalResolutionValue',
                        label: 'Temporal Resolution',
                        placeholder: 'Enter temporal resolution',
                        help: 'The typical temporal granularity/frequency of the data included in an asset.',
                        helpPosition: 'before',
                        validation: 'bail|required|greaterThan:0|lessThanOrEqual:100000',
                        validationRules: {
                            greaterThan: ({ value }: any) => value > 0,
                            lessThanOrEqual: ({ value }: any) => value <= 100000,
                        },
                        validationMessages: {
                            greaterThan: 'Temporal Resolution must be greater than 0.',
                            lessThanOrEqual: 'Temporal Resolution must be less than or equal to 100000.',
                        },
                        validationName: 'temporal Resolution',
                        inputClass: 'form-input',
                        labelClass: ['pb-1'],
                        errorBehavior: 'value',
                    },
                ]);
            return schema;
        }
        return [];
    });

    const getAccrualPeriodicitySchema = computed(() => {
        if (asset.value?.metadata?.distribution?.accrualPeriodicity) {
            const schema: any = [
                {
                    type: 'select',
                    name: 'unit',
                    key: 'accrualPeriodicityUnit',
                    label: 'Accrual Periodicity Unit',
                    placeholder: 'Select accrual periodicity unit',
                    options: assetAccrualPeriodicityOptions.value,
                    help: 'The typical frequency with which up-to-date data are expected to be added to the asset',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'accrual periodicity unit',
                    inputClass: 'form-select w-full',
                    labelClass: ['pb-1'],
                    errorBehavior: 'value',
                },
            ];

            if (
                asset.value.metadata.distribution.accrualPeriodicity?.unit &&
                asset.value.metadata.distribution.accrualPeriodicity.unit !== AccrualPeriodicityInterval.NA
            )
                Array.prototype.push.apply(schema, [
                    {
                        type: 'number',
                        name: 'value',
                        key: 'accrualPeriodicityValue',
                        label: 'Accrual Periodicity',
                        placeholder: 'Enter accrual periodicity',
                        help: `The typical frequency in ${
                            AccrualPeriodicityUnits[asset.value.metadata.distribution.accrualPeriodicity.unit]
                                .extendedPlural
                        } with which up-to-date data are expected to be added to the asset`,
                        helpPosition: 'before',
                        validation: 'bail|required|greaterThan:0',
                        validationRules: { greaterThan: ({ value }: any) => value > 0 },
                        validationMessages: { greaterThan: 'Accrual periodicity must be greater than 0.' },
                        validationName: 'accrual Periodicity',
                        inputClass: 'form-input',
                        labelClass: ['pb-1'],
                        errorBehavior: 'value',
                    },
                ]);

            return schema;
        }
        return [];
    });

    const assetOfflineRetentionOptions = computed(() =>
        asset.value?.structure?.type === 'other' ? otherFilesRetentionOptions : offlineRetentionOptions,
    );

    const getSpatialResolutionSchema = computed(() => {
        return [
            {
                type: 'select',
                name: 'unit',
                key: 'spatialResolutionUnit',
                label: 'Spatial Resolution Unit',
                placeholder: 'Select spatial resolution unit',
                options: spatialResolutionOptions,
                help:
                    'The granularity applied within the data allowing to distinguish different spaces using the data; either in terms of actual space/ground area (e.g. room, zone, building, country etc.) or as defined by a sensor/sensor network.',
                helpPosition: 'before',
                validation: 'required',
                validationName: 'spatial Resolution Unit',
                inputClass: 'form-select',
                labelClass: ['pb-1'],
                errorBehavior: 'value',
            },
        ];
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        let items: any = null;
        if (asset.value?.metadata?.license) {
            items = [
                {
                    type: 'treeselect',
                    name: 'license',
                    label: 'Licence',
                    placeholder: 'Select licence',
                    clearable: false,
                    disableBranchNodes: true,
                    options: licenseOptions,
                    help:
                        'The legal statement/terms giving official permission to the data asset in a custom manner or according to a well-defined data license.',
                    helpPosition: 'before',
                    validation: 'required',
                    labelClass: ['pb-1'],
                    disabled: accessLevel.value === AccessLevel.SelectiveSharing,
                    input: () => {
                        //
                    },
                    select: checkLicense,
                    errorBehavior: 'value',
                },
                {
                    type: 'text',
                    name: 'link',
                    label: 'Link',
                    placeholder: 'Enter link',
                    help: 'A link to the exact legal terms of the specific licence.',
                    helpPosition: 'before',
                    validation: asset.value.metadata.license.link
                        ? 'bail|url|min:5,length|max:300,length|matches:/^[a-zA-Z0-9.:/-]+$/'
                        : null,
                    validationMessages: {
                        matches: 'Link can only contain letters, numbers, dots, dashes, slashes and colons.',
                    },
                    disabled: !customLicense.value,
                    inputClass: 'form-input',
                    labelClass: ['pb-1'],
                    errorBehavior: 'value',
                },
                {
                    type: 'multiselect',
                    name: 'expectedUsage',
                    label: 'Expected Usage',
                    placeholder: 'Select expected usage',
                    options: expectedUsageOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help:
                        'The specific data asset can be used for data analytics purposes in the following specified locations.',
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'expected Usage',
                    customLabel: (expectedUsage: string) => expectedUsageOptions[expectedUsage],
                    disabled: accessLevel.value === AccessLevel.Public,
                    errorBehavior: 'submit',
                    labelClass: ['pb-1'],
                },
                {
                    type: 'checkbox',
                    name: 'offlineRetention',
                    label: 'Offline Retention',
                    options: assetOfflineRetentionOptions.value,
                    help:
                        'An indication whether downloading outside the Energy Data Space (e.g. as a file or through an API) is permitted for the data asset.',
                    helpPosition: 'before',
                    disabled: accessLevel.value === AccessLevel.Public,
                    inputClass: 'inline-flex',
                },
            ];
            Array.prototype.push.apply(schema, items);
        }
        return schema;
    });

    const getPricingSchema = computed(() => {
        let schema: any = [];
        let items: any = null;
        if (asset.value?.metadata.pricing) {
            schema = [
                {
                    type: 'multiselect',
                    name: 'reimbursementMethod',
                    label: 'Reimbursement Method',
                    placeholder: 'Select reimbursement method',
                    options: reimbursementMethodOptions,
                    searchable: false,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: false,
                    help: `The reimbursement method that the data provider has defined in order to acquire the data asset. Such a method ranges from a monetary transaction (i.e. the payment may be conducted “offline” - in respect to SYNERGIES - through a credit/debit card or a bank transfer, or “online” through crypto-currency in SYNERGIES), to a bartering/non-fiscal transaction (that offers the exchange of another data asset as compensation).`,
                    helpPosition: 'before',
                    validation: 'required',
                    validationName: 'reimbursement Method',
                    customLabel: (reimbursementMethod: string) => reimbursementMethodOptions[reimbursementMethod],
                    errorBehavior: 'submit',
                    labelClass: ['pb-2'],
                },
            ];

            if (
                asset.value?.metadata?.pricing?.reimbursementMethod?.includes('bank') ||
                asset.value?.metadata?.pricing?.reimbursementMethod?.includes('online')
            ) {
                items = [
                    {
                        type: 'select',
                        name: 'calculationScheme',
                        label: 'Calculation Scheme',
                        placeholder: 'Select calculation scheme',
                        options:
                            assetType.value === AssetType.Model
                                ? Object.keys(calculationSchemeOptions).filter((key: string) => key !== 'Fixed per Row')
                                : calculationSchemeOptions,
                        help:
                            'The cost calculation scheme that is applied for the asset and may range from fixed per row and fixed per asset to request dependent.',
                        helpPosition: 'before',
                        validation: 'required',
                        errorBehavior: 'submit',
                        validationName: 'calculation Scheme',
                        inputClass: 'form-select',
                        labelClass: ['pb-2'],
                    },
                ];
                Array.prototype.push.apply(schema, items);
            }
            if (
                asset.value?.metadata.pricing.calculationScheme &&
                asset.value?.metadata.pricing.calculationScheme !== 'Request Dependent'
            ) {
                items = [
                    {
                        type: 'group',
                        name: 'costCurrency',
                        label: 'Cost',
                        help:
                            'The price for the acquisition of the data asset (as a whole or for each of its rows), including any value added tax and its currency.',
                        helpPosition: 'before',
                        children: [
                            {
                                component: 'div',
                                class: 'grid grid-cols-3 gap-5',
                                children: [
                                    {
                                        component: 'div',
                                        class: 'col-span-2',
                                        children: [
                                            {
                                                type: 'number',
                                                name: 'cost',
                                                placeholder: 'Enter cost',
                                                validation: 'bail|required|min:0|max:1000000',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-input',
                                            },
                                        ],
                                    },
                                    {
                                        component: 'div',
                                        class: 'col-span-1',
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'currency',
                                                placeholder: 'Select currency',
                                                options: currencyOptions,
                                                validation: 'required',
                                                errorBehavior: 'submit',
                                                inputClass: 'form-select',
                                                value: 'EUR',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ];
                Array.prototype.push.apply(schema, items);
            }
        }

        return schema;
    });

    const initAsset = (blocks: {
        general: boolean;
        distribution: boolean;
        extent: boolean;
        licensing: boolean;
        pricing: boolean;
    }) => {
        const emptyAsset: any = { name: null, description: null, status: null, metadata: {} };
        if (blocks.general) emptyAsset.metadata.general = { tags: [] };
        if (blocks.distribution)
            emptyAsset.metadata.distribution = { format: [], accrualPeriodicity: { value: null, unit: null } };
        if (blocks.extent)
            emptyAsset.metadata.extent = {
                temporalCoverage: {
                    type: null,
                    field: null,
                    value: null,
                    min: null,
                    max: null,
                    unit: null,
                    timePeriod: [{ min: null, max: null }],
                },
                spatialCoverage: {
                    type: null,
                    value: null,
                    values: [],
                    coordinates: { lat: null, lon: null },
                    field: null,
                    unit: null,
                    exactLocation: [{ lat: null, lon: null }],
                },
                temporalResolution: { value: null, unit: null },
                spatialResolution: { unit: null },
            };
        if (blocks.licensing)
            emptyAsset.metadata.license = {
                accessLevel: null,
                license: null,
                copyrightOwner: null,
                link: null,
                offlineRetention: [],
                expectedUsage: [],
            };

        if (blocks.pricing) {
            emptyAsset.metadata.pricing = {
                cost: null,
                currency: 'EUR',
                reimbursementMethod: [],
                calculationScheme: null,
            };
        }
        return emptyAsset;
    };

    const mergeAsset = (emptyAsset: any, databaseAsset: any) => {
        const mergedAsset = {
            ...emptyAsset,
            ...databaseAsset,
            metadata: { ...emptyAsset.metadata, ...databaseAsset.metadata },
        };

        if (mergedAsset.metadata) {
            // General metadata
            if (mergedAsset.metadata.general?.tags) tagOptions.value = R.clone(mergedAsset.metadata.general.tags);

            // License metadata
            if (mergedAsset.metadata.license) {
                if (mergedAsset.accessLevel) accessLevel.value = mergedAsset.accessLevel;
                if (mergedAsset.metadata.license.copyrightOwner)
                    copyrightOwner.value = mergedAsset.metadata.license.copyrightOwner;
            }

            // Extent metadata
            if (mergedAsset.metadata.extent) {
                // for when a mapped field used in temporal coverage is removed in revised mapping
                if (mergedAsset.metadata.extent.temporalCoverage === null)
                    mergedAsset.metadata.extent.temporalCoverage = R.clone(emptyAsset.metadata.extent.temporalCoverage);

                if (mergedAsset.metadata.extent.temporalCoverage) {
                    if (
                        mergedAsset.metadata.extent.temporalCoverage.unit === 'Time Period' &&
                        mergedAsset.metadata.extent.temporalCoverage.max &&
                        mergedAsset.metadata.extent.temporalCoverage.min
                    ) {
                        const { min } = mergedAsset.metadata.extent.temporalCoverage;
                        const { max } = mergedAsset.metadata.extent.temporalCoverage;
                        mergedAsset.metadata.extent.temporalCoverage.timePeriod = [{ min, max }];
                    }
                }

                // for when a mapped field used in spatial coverage coverage is removed in revised mapping
                if (mergedAsset.metadata.extent.spatialCoverage === null)
                    mergedAsset.metadata.extent.spatialCoverage = R.clone(emptyAsset.metadata.extent.spatialCoverage);

                if (mergedAsset.metadata.extent.spatialCoverage) {
                    if (mergedAsset.metadata.extent.spatialCoverage.coordinates) {
                        const { lat } = mergedAsset.metadata.extent.spatialCoverage.coordinates;
                        const { lon } = mergedAsset.metadata.extent.spatialCoverage.coordinates;
                        mergedAsset.metadata.extent.spatialCoverage.exactLocation = [{ lat, lon }];
                    }
                }
            }

            // Distribution metadata
            if (mergedAsset.metadata.distribution) {
                if (
                    !R.is(Object, mergedAsset.metadata.distribution.accrualPeriodicity) ||
                    !R.has('unit', mergedAsset.metadata.distribution.accrualPeriodicity) ||
                    !R.has('value', mergedAsset.metadata.distribution.accrualPeriodicity)
                )
                    mergedAsset.metadata.distribution.accrualPeriodicity = { value: null, unit: null };
                if (mergedAsset.metadata.distribution?.accrualPeriodicity?.unit === 'Not applicable')
                    mergedAsset.metadata.distribution.accrualPeriodicity.value = null;
            }

            if (
                mergedAsset.metadata.pricing &&
                mergedAsset.metadata.pricing.cost !== null &&
                mergedAsset.metadata.pricing.currency
            ) {
                const cost = mergedAsset.metadata.pricing.cost.toString();
                const { currency } = mergedAsset.metadata.pricing;
                mergedAsset.metadata.pricing.costCurrency = [{ cost, currency }];
            }
        }
        asset.value = R.clone(mergedAsset);
        if (asset.value?.structure?.domain) fetchSpatialIDFields(asset.value.structure.domain);
    };

    const getDomain = (domainUid: string, majorVersion: number): Promise<Model> => {
        return new Promise((resolve, reject) => {
            const availableDomain = domains.value.find(
                (domain: Model) => domain.uid === domainUid && domain.majorVersion === majorVersion,
            );
            if (availableDomain) resolve(availableDomain);
            else
                exec(ModelAPI.getModelByUid(domainUid, majorVersion))
                    .then((res: any) => {
                        resolve(res.data);
                    })
                    .catch((e) => reject(e));
        });
    };

    watch(
        () => asset.value?.metadata?.extent?.temporalResolution?.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit && unit === 'Not applicable')
                asset.value.metadata.extent.temporalResolution.value = null;
        },
    );

    watch(
        () => asset.value?.metadata?.distribution?.accrualPeriodicity?.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit && unit === 'Not applicable')
                asset.value.metadata.distribution.accrualPeriodicity.value = null;
        },
    );

    const fetchSpatialIDFields = (domain: any) => {
        exec(ModelAPI.spatialConcepts(domain.uid, domain.majorVersion)).then((res: any) => {
            spatialIDfields.value = res.data;
        });
    };

    const assetSpatialCoverageOptions = computed(() => {
        const spatialIDOptions = {};
        spatialIDfields.value.forEach((field: any) => {
            if (!Object.keys(spatialIDOptions).includes(field.spatialField))
                spatialIDOptions[field.spatialField] = field.spatialField;
        });
        if (
            asset.value?.structure &&
            asset.value.structure.schema &&
            asset.value.structure.spatialFields &&
            asset.value.structure.spatialFields.length !== 0 &&
            assetType.value === AssetType.Dataset
        )
            return {
                ...spatialCoverageOptions,
                ...spatialIDOptions,
                'Calculated based on data': 'Calculated based on data',
            };
        return { ...spatialCoverageOptions, ...spatialIDOptions };
    });

    const assetTemporalCoverageOptions = computed(() => {
        if (
            asset.value?.structure &&
            asset.value.structure.schema &&
            asset.value.structure.temporalFields &&
            asset.value.structure.temporalFields.length !== 0 &&
            assetType.value === AssetType.Dataset
        )
            return { ...temporalCoverageOptions, 'Calculated based on data': 'Calculated based on data' };
        return temporalCoverageOptions;
    });

    const assetAccrualPeriodicityOptions = computed(() => {
        const singular = asset.value?.metadata?.distribution?.accrualPeriodicity?.value === '1';

        return Object.values(AccrualPeriodicityInterval).reduce((acc: Record<string, string>, key: string) => {
            if (key !== AccrualPeriodicityInterval.NA)
                acc[key] = singular
                    ? AccrualPeriodicityUnits[key].extendedSingular
                    : AccrualPeriodicityUnits[key].extendedPlural;

            return acc;
        }, {});
    });

    watch(
        () => asset.value?.metadata?.extent?.spatialCoverage?.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit)
                asset.value.metadata.extent.spatialCoverage = {
                    type: null,
                    value: null,
                    values: [],
                    coordinates: {
                        lat: null,
                        lon: null,
                    },
                    field: null,
                    unit,
                    exactLocation: [{ lat: null, lon: null }],
                };
        },
    );

    watch(
        () => asset.value?.metadata?.extent?.temporalCoverage.unit,
        (unit, oldUnit) => {
            if (oldUnit && oldUnit !== unit)
                asset.value.metadata.extent.temporalCoverage = {
                    type: null,
                    field: null,
                    value: null,
                    min: null,
                    max: null,
                    unit,
                    timePeriod: [{ min: null, max: null }],
                };
        },
    );

    return {
        asset,
        accessLevel,
        copyrightOwner,
        domainsIdToUID,
        domainsOptions,
        locationOptions,
        accessLevelOptions,
        spatialIDfieldName,
        customLicense,
        temporalFields,
        spatialFields,
        createTreeStructure,
        getGeneralSchema,
        getDistributionSchema,
        getTemporalResolutionSchema,
        getSpatialResolutionSchema,
        getLicensingSchema,
        initAsset,
        mergeAsset,
        checkLicense,
        assetTemporalCoverageOptions,
        assetAccrualPeriodicityOptions,
        assetSpatialCoverageOptions,
        domains,
        assetType,
        fetchSpatialIDFields,
        getDomain,
        getAccrualPeriodicitySchema,
        getPricingSchema,
        expectedUsageOptions,
        reimbursementMethodOptions,
    };
}
